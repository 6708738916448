/* classroom style guide */

//color
$orange-red: #ff4338;
$primary-color: $orange-red;

// font
$font-family-base: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol' !default;
$font-size-base: 14px !default;
$font-size-x-large: ceil(($font-size-base * 1.64)) !default; // ~23px
$font-size-large: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small: ceil(($font-size-base * 0.85)) !default; // ~12px

//screen
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
$screen-x-lg: 1680px !default;
$screen-x-lg-min: $screen-lg !default;

// room layout
$boooody-header: 72px;
$rightsection-width: 400px;
$footbar-height: 78px;
$video_mini_width: 270px;
$video_mini_height: 150px;
