@tailwind base;

@tailwind components;

@tailwind utilities;

.h1 {
  @apply text-gray1;
  @apply text-2xl;
  @apply font-semibold;
  line-height: 40px;
}

@media (min-width: 768px) {
  .h1 {
    @apply text-4xl;
    line-height: 60px;
  }
}

.h2 {
  @apply text-gray1;
  @apply font-medium;
  font-size: 30px;
  line-height: 38px;
}

@media (min-width: 768px) {
  .h2 {
    @apply text-3xl;
    line-height: 48px;
  }
}

.h3 {
  @apply text-gray1;
  @apply font-medium;
  font-size: 28px;
  line-height: 36px;
}

@media (min-width: 768px) {
  .h3 {
    @apply text-2xl;
    line-height: 40px;
  }
}

.h4 {
  @apply text-gray1;
  @apply text-xl;
  @apply font-medium;
  line-height: 32px;
}

.h5 {
  @apply text-gray1;
  @apply text-lg;
  @apply font-medium;
  line-height: 28px;
}

h6,
.h6 {
  @apply text-gray1;
  @apply text-base;
  @apply font-medium;
  line-height: 24px;
}

.regular-body {
  @apply text-base;
  @apply font-normal;
  line-height: 24px;
}

.small-secondary {
  @apply text-sm;
  @apply font-normal;
  line-height: 22px;
}

.tiny-caption {
  @apply text-tiny;
  @apply font-normal;
  line-height: 18px;
}

.text-button {
  @apply font-medium;
  @apply text-sm;
  letter-spacing: 0.75px;
}

.text-overline {
  @apply m-1;
  @apply text-gray3;
  @apply text-tiny;
  @apply font-light;
  letter-spacing: 0.75px;
}

.big-numbers {
  @apply text-3xl;
  @apply font-light;
}

.speech-italic {
  @apply text-gray2;
  @apply italic;
  @apply font-normal;
  line-height: 22px;
  letter-spacing: -0.01px;
}

.text-link {
  @apply mx-1;
  @apply font-normal;
  @apply underline;
  @apply text-special4;
  line-height: 24px;
}

.text-link:hover {
  @apply text-gray1;
}

.small-dot {
  @apply rounded-full;
  width: 8px;
  height: 8px;
}

.border-b-gray {
  @apply border-0;
  @apply border-solid;
  @apply border-b;
  @apply border-gray6;
}

.truncate-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.truncate-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.truncate-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.grecaptcha-badge {
  visibility: hidden;
}

.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
