@import 'style/var';
@import 'style/tailwind';

html {
  font-size: $font-size-base;
  font-family: $font-family-base;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-family-base;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5715;
  font-weight: 300;
}

// ??? 莫名奇妙的会导致修改代码后 页面更新 被覆盖一层满屏的 iframe
body > iframe {
  display: none !important;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

svg {
  display: block;
}

// ds2.0 更新后 a 标签的 带 link 的会出现下划线
a:any-link {
  text-decoration: none;
}

.primary-color {
  color: $primary-color;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
